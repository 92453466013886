<template>
  <v-card
    class="mt-0"
    elevation="5"
    :loading="loading"
  >
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
      >
        <v-icon
          size="45"
          :class="colorBox"
        >
          {{ iconBox }}
        </v-icon>
        <div class="mr-5">
          <v-progress-circular
            v-if="loading"
            color="primary"
            :size="50"
            :value="progressTxt"
            indeterminate
            class="vpc-importacoes-bdgd"
          >
            <label style="font-size: 10px">{{ progressTxt }}%</label>
          </v-progress-circular>
        </div>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-0">
          {{ title }}
        </v-list-item-title>
        <div class="text-overline mb-1">
          {{ infoBoxNumber }}
        </div>
        <v-progress-linear
          :value="progress"
          :color="colorProgressLinear"
        />
        <v-list-item-subtitle
          >{{ progressDescription }}: {{ progressTxt }}%</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    statusInicial: {
      type: String,
      required: true
    },
    numeroArquivos: {
      type: Number,
      default: 0
    },
    numeroEntidades: {
      type: Number,
      default: 0
    },
    numeroItensProcessadosInicial: {
      /* numeroArquivosProcessadosInicial || numeroEntidadesProcessadasInicial */
      type: Number,
      required: true
    },
    isPreImport: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    status: 'aguardando',
    numeroItensProcessados: 0 /* numeroArquivosProcessados || numeroEntidadesProcessadas */
  }),

  mounted() {
    this.status = this.$props.statusInicial.toLowerCase();
    this.numeroItensProcessados = this.$props.numeroItensProcessadosInicial;
  },

  computed: {
    title() {
      return this.isPreImport
        ? `Pré-importação: ${this.status}`
        : `Importação: ${this.status}`;
    },

    iconBox() {
      switch (this.status) {
        case 'aguardando':
          return 'mdi-clock-outline';
        case 'sucesso':
          return 'mdi-check-bold';
        case 'executando':
          break;
        case 'falha':
          return 'mdi-alert';
      }
      return 'mdi-alert';
    },

    colorBox() {
      switch (this.status) {
        case 'aguardando':
          return 'border-c-blue';
        case 'sucesso':
          return 'border-c-green';
        case 'executando':
          return 'border-c-primary';
        case 'falha':
          return 'border-c-red';
      }
      return 'border-c-red';
    },

    colorProgressLinear() {
      switch (this.status) {
        case 'aguardando':
          return '#17a2b8';
        case 'sucesso':
          return '#438102';
        case 'executando':
          return '#003a63';
        case 'falha':
          return 'red';
      }
      return 'red';
    },

    infoBoxNumber() {
      let txt = '';

      if (this.isPreImport) {
        txt += this.numeroArquivos + ' ';
        txt += this.numeroArquivos > 1 ? 'arquivos' : 'arquivo';
        txt += ` de ${this.numeroEntidades} `;
      } else {
        txt = this.numeroEntidades + ' ';
      }

      txt += this.numeroEntidades > 1 ? 'entidades' : 'entidade';
      return txt;
    },

    progress() {
      let tot = this.isPreImport ? this.numeroArquivos : this.numeroEntidades;
      const progress = (this.numeroItensProcessados / tot) * 100;
      return progress >= 100 ? 100 : progress;
    },

    progressTxt() {
      return this.progress.toFixed(2);
    },

    progressDescription() {
      let txt = this.numeroItensProcessados + ' ';
      if (this.isPreImport) {
        txt += this.numeroItensProcessados > 1 ? 'arquivos ' : 'arquivo ';
        txt += this.numeroItensProcessados > 1 ? 'processados' : 'processado';
      } else {
        txt +=
          (this.numeroItensProcessados > 1 ? 'entidades' : 'entidade') + ' ';
        txt += this.numeroItensProcessados > 1 ? 'processadas' : 'processada';
      }
      return txt;
    },

    loading() {
      return this.status == 'executando' ? true : false;
    }
  },

  watch: {
    statusInicial(newValue) {
      this.status = newValue.toLowerCase();
    },
    numeroItensProcessadosInicial(newValue) {
      this.numeroItensProcessados = newValue;
    }
  }
};
</script>

<style>
.vpc-importacoes-bdgd div label {
  font-size: 8.5px !important;
}
</style>
